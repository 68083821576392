<script>
import TablePage from '@/found/components/table_page';
import * as utils from '../../../utils/index';

export default {
  name: 'customer_scan_list',
  extends: TablePage,
  data() {
    return {
      requestType: 'POST',
      requestUrl: '/cps-mobile/consumer/scan/activity/consumerScanActivityReport/findConsumerScanReport', // 最新
      params: {},
      formConfig: {},
      modalConfig: {},
    };
  },
  props: {
    propsObj: Object,
  },
  async created() {
    this.searchFormData.createStartTime = utils.dateFormat('YYYY-mm-dd 00:00:00', new Date());
    this.searchFormData.createEndTime = utils.dateFormat('YYYY-mm-dd 23:59:59', new Date());
    this.params = { custCode: this.propsObj.row.customerCode };
    await this.getConfigList('cps-web-customer_record_flow-list');
  },
  methods: {
    // 判断是否有‘createTimeStart’字段
    hasCreateTimeStart() {
      const index = this.searchList.findIndex((item) => item.field === 'createStartTime');
      if (index > -1) {
        return true;
      }
      return false;
    },
    // 判断是否有‘createTimeEnd’字段
    hasCreateTimeEnd() {
      const index = this.searchList.findIndex((item) => item.field === 'createEndTime');
      if (index > -1) {
        return true;
      }
      return false;
    },
    // 获取列表数据前置函数
    beforeGetList() {
      if (this.hasCreateTimeStart()) {
        this.formData.createStartTime = this.searchFormData.createStartTime || null;
      } else if (this.searchFormData.createStartTime) {
        delete this.searchFormData.createStartTime;
      }
      if (this.hasCreateTimeEnd()) {
        this.formData.createEndTime = this.searchFormData.createEndTime || null;
      } else if (this.searchFormData.createEndTime) {
        delete this.searchFormData.createEndTime;
      }
      return true;
    },
    /** @override 重写重置按钮 */
    beforeSearchEvent({ $event }) {
      if ($event.type === 'reset' && this.hasCreateTimeStart() && this.hasCreateTimeEnd()) {
        this.searchFormData.createStartTime = utils.dateFormat('YYYY-mm-dd 00:00:00', new Date());
        this.formData.createStartTime = this.searchFormData.createStartTime;
        this.searchFormData.createEndTime = utils.dateFormat('YYYY-mm-dd 23:59:59', new Date());
        this.formData.createEndTime = this.searchFormData.createEndTime;
        return true;
      }
      return true;
    },
  },
};
</script>
