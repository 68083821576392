import TablePage from '../../../../components/table_page';
import PurchaseOrderList from './form/purchase_order_list.vue';
import DealerScanList from './form/dealer_scan_code_information.vue';
import TerminalScanList from './form/terminal_scan_code_information.vue';
import CustomerScanList from './form/customer_scan_code_information.vue';

export default {
  name: 'dealer_related_information_report',
  extends: TablePage,
  data() {
    return {};
  },
  components: {
    PurchaseOrderList,
    DealerScanList,
    TerminalScanList,
    CustomerScanList,
  },
  methods: {
    cellClick({ row, column }) {
      if (column.property === 'orderNum') {
        this.formName = 'PurchaseOrderList';
        this.propsObjInData.row = row;
        this.modalConfig.title = '需货订单信息';
        this.openFull();
      }
      if (column.property === 'customerInNum') {
        this.formName = 'DealerScanList';
        this.propsObjInData.row = row;
        this.modalConfig.title = '经销商扫码信息';
        this.openFull();
      }
      if (column.property === 'terminalInNum') {
        this.formName = 'TerminalScanList';
        this.propsObjInData.row = row;
        this.modalConfig.title = '终端扫码信息';
        this.openFull();
      }
      if (column.property === 'openNum') {
        this.formName = 'CustomerScanList';
        this.propsObjInData.row = row;
        this.modalConfig.title = '消费者扫码信息';
        this.openFull();
      }
    },
    modalClick({ val, row }) {
      if (val.code === 'purchase_order_information') {
        this.formName = 'PurchaseOrderList';
        this.propsObjInData.row = row;
        this.modalConfig.title = '需货订单信息';
        this.openFull();
      }
      if (val.code === 'dealer_scan_code_information') {
        this.formName = 'DealerScanList';
        this.propsObjInData.row = row;
        this.modalConfig.title = '经销商扫码信息';
        this.openFull();
      }
      if (val.code === 'terminal_scan_code_information') {
        this.formName = 'TerminalScanList';
        this.propsObjInData.row = row;
        this.modalConfig.title = '终端扫码信息';
        this.openFull();
      }
      if (val.code === 'customer_scan_code_information') {
        this.formName = 'CustomerScanList';
        this.propsObjInData.row = row;
        this.modalConfig.title = '消费者扫码信息';
        this.openFull();
      }
    },
  },
  created() {
    this.getConfigList('customer_scan_list');
  },

};
