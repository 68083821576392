<template>
  <div class="needGoodsForm">
    <!-- 弹窗form -->
    <el-drawer custom-class="form_drawer"
               :title="drawerTitle"
               size="80%"
               :visible.sync="formShow"
               @closeDrawer="closeDrawer"
               :modal-append-to-body="false">
      <!-- 表单 -->
      <DrawerComponent :button="drawerButton"
                       @colse="formShow = false"
                       :loading="loading"
                       :drawerConfig="drawerConfig"
                       @save="requestSave">
        <div class="drawer-content-item">
          <div>
            <div class="drawer-content-title">结算信息</div>
            <div class="slot-body">
              <div>
                <span class="label">订单总金额（元）:</span>
                <el-input disabled
                          v-model="drawerConfig[0].ruleForm.amount" />
              </div>
              <div v-if="drawerConfig[0].ruleForm.orderType === '4'">
                <span class="label">货补产品（元）:</span>
                <el-input disabled
                          v-model="drawerConfig[0].ruleForm.supplementAmount" />
              </div>
              <div>
                <span class="label">应付合计（元）:</span>
                <el-input disabled
                          v-model="drawerConfig[0].ruleForm.shouldPayAmount" />
              </div>
            </div>
            <div class="drawer-content-title">结算方式</div>
            <div class="slot-body">

              <!-- 业务类型B类 使用B类资金池-->
              <div v-if="drawerConfig[0].ruleForm.businessType === 'B'">
                <span class="label">B类资金余额（元）:</span>
                <el-input disabled
                          v-model="drawerConfig[0].ruleForm.balance" />
              </div>
              <div v-if="drawerConfig[0].ruleForm.businessType === 'B'">
                <span class="label">B类费用支付（元）:</span>
                <el-input disabled
                          @input="calculateCaptital('bcaptitalAmount')"
                          v-model="drawerConfig[0].ruleForm.bcaptitalAmount" />
              </div>

              <!--  订单类型货补  使用资金池-->
              <div v-if="drawerConfig[0].ruleForm.orderType === '4'">
                <span class="label">货补资金余额:</span>
                <el-input disabled
                          v-model="drawerConfig[0].ruleForm.balance" />
              </div>
              <div v-if="drawerConfig[0].ruleForm.orderType === '4'">
                <span class="label">货补资金支付（元）:</span>
                <el-input disabled
                          v-model="drawerConfig[0].ruleForm.captitalAmount" />
              </div>

              <!-- 非货补订单 使用账户余额 -->
              <div v-if="drawerConfig[0].ruleForm.orderType !== '4' && drawerConfig[0].ruleForm.orderType !== '7'">
                <span class="label">资金账户余额（元）:</span>
                <el-input disabled
                          v-model="drawerConfig[0].ruleForm.accountAmountUseFulCash" />
              </div>
              <div v-if="drawerConfig[0].ruleForm.orderType !== '4'">
                <span class="label">资金账户支付（元）:</span>
                <el-input disabled
                          type="number"
                          v-model="drawerConfig[0].ruleForm.captitalAmount1" />
              </div>

              <div>
                <span class="label">本单实际支付（元）:</span>
                <el-input disabled
                          v-model="drawerConfig[0].ruleForm.actualAmount" />
              </div>
            </div>
          </div>
        </div>
      </DrawerComponent>
      <!-- 弹窗商品列表 -->
      <div v-if="drawerShowProduct">
        <el-drawer custom-class="form_drawer"
                   title="添加商品"
                   size="80%"
                   :visible.sync="drawerShowProduct"
                   :modal="false"
                   :append-to-body="true">
          <div class="drawer-table">
            <TableComponent @searchData="selectSearchProduct"
                            :templateData="tableDataProduct"
                            :pagination="tablePaginationProduct"
                            :table-loading="tableLoadingProduct"
                            :searchConfig="searchConfigProduct"
                            :table-config="tableConfigProduct"></TableComponent>
          </div>
          <div class="drawer-button">
            <buttonComponent @clickEvent="beforeSaveSelect"
                             :btnType="'primary'"
                             :icon="'el-icon-check'">保存
            </buttonComponent>
            <buttonComponent @clickEvent="drawerShowProduct = false"
                             :btnType="'danger'"
                             :icon="'el-icon-close'">关闭
            </buttonComponent>
          </div>
        </el-drawer>
      </div>
      <!-- 客户列表 -->
      <div>
        <el-drawer custom-class="form_drawer"
                   title="选择客户"
                   size="80%"
                   :visible.sync="drawerShowCustomer"
                   :modal="false"
                   :append-to-body="true">
          <div class="drawer-table">
            <TableComponent @searchData="searchCustomer"
                            :templateData="tableDataCustomer"
                            :table-loading="tableLoadingCustomer"
                            :searchConfig="searchConfigCustomer"
                            :table-config="tableConfigCustomer"></TableComponent>
          </div>
          <div class="drawer-button">
            <buttonComponent @clickEvent="beforeSaveSelect"
                             :btnType="'primary'"
                             :icon="'el-icon-check'">保存
            </buttonComponent>
            <buttonComponent @clickEvent="drawerShowCustomer = false"
                             :btnType="'danger'"
                             :icon="'el-icon-close'">关闭
            </buttonComponent>
          </div>
        </el-drawer>
      </div>
      <!-- 关联活动列表 -->
      <div>
        <el-drawer custom-class="form_drawer"
                   title="选择关联活动"
                   size="80%"
                   :visible.sync="drawerShowAct"
                   :modal="false"
                   :append-to-body="true">
          <div class="drawer-table">
            <TableComponent @searchData="searchAct"
                            :templateData="tableDataAct"
                            :table-loading="tableLoadingAct"
                            :searchConfig="searchConfigAct"
                            :table-config="tableConfigAct"
                            :pagination="paginationAct"></TableComponent>
          </div>
          <div class="drawer-button">
            <buttonComponent @clickEvent="beforeSaveSelect"
                             :btnType="'primary'"
                             :icon="'el-icon-check'">保存
            </buttonComponent>
            <buttonComponent @clickEvent="drawerShowAct = false"
                             :btnType="'danger'"
                             :icon="'el-icon-close'">关闭
            </buttonComponent>
          </div>
        </el-drawer>
      </div>
      <!-- 负责人列表 -->
      <div>
        <el-drawer custom-class="form_drawer"
                   title="选择负责人"
                   size="80%"
                   :visible.sync="drawerShowResponese"
                   :modal="false"
                   :append-to-body="true">
          <div class="drawer-table">
            <TableComponent @searchData="searchResponeseAll"
                            :templateData="tableDataResponese"
                            :table-loading="tableLoadingResponese"
                            :searchConfig="searchConfigResponese"
                            :table-config="tableConfigResponese"
                            :pagination="pagination"></TableComponent>
          </div>
          <div class="drawer-button">
            <buttonComponent @clickEvent="beforeSaveSelect"
                             :btnType="'primary'"
                             :icon="'el-icon-check'">保存
            </buttonComponent>
            <buttonComponent @clickEvent="drawerShowResponese = false"
                             :btnType="'danger'"
                             :icon="'el-icon-close'">关闭
            </buttonComponent>
          </div>
        </el-drawer>
      </div>
      <!-- 开票列表 -->
      <div>
        <el-drawer custom-class="form_drawer"
                   title="选择开票人"
                   size="80%"
                   :visible.sync="drawerShowTicket"
                   :modal="false"
                   :append-to-body="true">
          <div class="drawer-table">
            <TableComponent @searchData="searchTicket"
                            :templateData="tableDataTicket"
                            :table-loading="tableLoadingTicket"
                            :searchConfig="searchConfigTicket"
                            :table-config="tableConfigTicket"></TableComponent>
          </div>
          <div class="drawer-button">
            <buttonComponent @clickEvent="beforeSaveSelect"
                             :btnType="'primary'"
                             :icon="'el-icon-check'">保存
            </buttonComponent>
            <buttonComponent @clickEvent="drawerShowTicket = false"
                             :btnType="'danger'"
                             :icon="'el-icon-close'">关闭
            </buttonComponent>
          </div>
        </el-drawer>
      </div>
      <div style="text-align:right;padding: 20px;"
           @click="carReport()"
           :loading="loading"
           v-if="isCart">
        <el-button type="primary">
          报车
        </el-button>
      </div>
    </el-drawer>

    <DrawerComponent v-if="dmsId"
                     :button="drawerButton"
                     @colse="formShow = false"
                     :loading="loading"
                     :drawerConfig="drawerConfig"
                     @save="requestSave">
      <div class="drawer-content-item">
        <div>
          <div class="drawer-content-title">结算信息</div>
          <div class="slot-body">
            <div>
              <span class="label">订单总金额（元）:</span>
              <el-input disabled
                        v-model="drawerConfig[0].ruleForm.amount" />
            </div>
            <div v-if="drawerConfig[0].ruleForm.orderType === '4'">
              <span class="label">货补产品（元）:</span>
              <el-input disabled
                        v-model="drawerConfig[0].ruleForm.supplementAmount" />
            </div>
            <div>
              <span class="label">应付合计（元）:</span>
              <el-input disabled
                        v-model="drawerConfig[0].ruleForm.shouldPayAmount" />
            </div>
          </div>
          <div class="drawer-content-title">结算方式</div>
          <div class="slot-body">

            <!-- 业务类型B类 使用B类资金池-->
            <div v-if="drawerConfig[0].ruleForm.businessType === 'B'">
              <span class="label">B类资金余额（元）:</span>
              <el-input disabled
                        v-model="drawerConfig[0].ruleForm.balance" />
            </div>
            <div v-if="drawerConfig[0].ruleForm.businessType === 'B'">
              <span class="label">B类费用支付（元）:</span>
              <el-input disabled
                        @input="calculateCaptital('bcaptitalAmount')"
                        v-model="drawerConfig[0].ruleForm.bcaptitalAmount" />
            </div>

            <!--  订单类型货补  使用资金池-->
            <div v-if="drawerConfig[0].ruleForm.orderType === '4'">
              <span class="label">货补资金余额:</span>
              <el-input disabled
                        v-model="drawerConfig[0].ruleForm.balance" />
            </div>
            <div v-if="drawerConfig[0].ruleForm.orderType === '4'">
              <span class="label">货补资金支付（元）:</span>
              <el-input disabled
                        v-model="drawerConfig[0].ruleForm.captitalAmount" />
            </div>

            <!-- 非货补订单 使用账户余额 -->
            <div v-if="drawerConfig[0].ruleForm.orderType !== '4' && drawerConfig[0].ruleForm.orderType !== '5' && drawerConfig[0].ruleForm.orderType !== '7' && drawerConfig[0].ruleForm.orderType !== '3'">
              <span class="label">资金账户余额（元）:</span>
              <el-input disabled
                        v-model="drawerConfig[0].ruleForm.accountAmountUseFulCash" />
            </div>
            <div v-if="drawerConfig[0].ruleForm.orderType !== '4' && drawerConfig[0].ruleForm.orderType !== '5' && drawerConfig[0].ruleForm.orderType !== '7' && drawerConfig[0].ruleForm.orderType !== '3'">
              <span class="label">资金账户支付（元）:</span>
              <el-input disabled
                        type="number"
                        v-model="drawerConfig[0].ruleForm.captitalAmount1" />
            </div>

            <div>
              <span class="label">本单实际支付（元）:</span>
              <el-input disabled
                        v-model="drawerConfig[0].ruleForm.actualAmount" />
            </div>
          </div>
        </div>
      </div>
    </DrawerComponent>
  </div>

</template>

<script>
import _ from 'xe-utils';
import { log } from 'mathjs';
import buttonComponent from '@/src/views/dms/compoment/button-component/button-component';
import TableComponent from '@/src/views/dms/compoment/table-component/table-component.vue';
import DrawerComponent from '@/src/views/dms/compoment/drawer-component/drawer-component';

export default {
  name: 'form-component',
  components: {
    TableComponent,
    DrawerComponent,
    buttonComponent,
  },
  props: ['dmsId', 'dmsCode'],
  data() {
    return {
      captitalAmountDisable: false, // 禁止资金池
      userInfo: JSON.parse(localStorage.getItem('userInfo')),
      selectTableData: [], // table列表选中的数据
      orderCode: '',
      id: '',
      loading: false,
      isCart: false, // 是否报车
      productType: '', // 商品类型 {0:货补资金,1:B类资金, 2:货补本品,3:本品}
      // ---------------------- 弹窗 - 合同新增表单配置
      formShow: false,
      drawerTitle: '新增',
      code: 'add',
      drawerButton: {
        show: true,
        options: [
          {
            text: '暂存',
            fn: () => {
              this.requestSave({}, 'Y');
            },
          },
        ],
      },
      drawerConfig: [ // 弹窗组件配置
        {
          title: '基本信息',
          type: 'form',
          ruleForm: { orderSourceType: 'back' },
          formConfig: { // 弹窗表单配置
            items: [
              [{
                label: '单据编号',
                key: 'orderCode',
                show: false,
              }, {
                label: '客户名称',
                key: 'custName',
                valueType: 'iconInput',

                icon: 'el-icon-circle-plus-outline',
                fn: () => {
                  this.searchCustomer({});
                },
              }, {
                label: '客户类型',
                key: 'custType',
                disabled: true,
                valueType: 'select',
                path: 'code?dictTypeCode=cust_type',
              },
              {
                label: '订单类型',
                key: 'orderType',
                valueType: 'select',
                options: {
                  label: 'orderTypeName',
                  value: 'orderType',
                },
                fn: (val) => {
                  this.setFormData('', 'companyName'); //  公司主体
                  this.setFormData('', 'companyCode'); //  公司主体Code
                  this.selectOrderType(val);
                  if (val === '1') { // 销售订单
                    this.getCompany(this.drawerConfig[0].ruleForm.custCode);
                    this.getCompanyType(this.drawerConfig[0].ruleForm.companyCode);
                  } else if (val === '2') { // 处理品 可以选择所有公司
                    this.getAllCompany();
                    this.getCompanyType(this.drawerConfig[0].ruleForm.companyCode);
                    this.setFormItemValue(['orderContactName', 'contactPhone'], 'disabled', false); // 客户信息可以编辑
                  } else if (val === '3' || val === '5') { // 促销品 || 免费订单
                    this.getAllCompany();
                    this.getCompanyType(this.drawerConfig[0].ruleForm.companyCode);
                  } else if (val === '4') { // 货补订单
                    this.getAllCompany();
                    this.getCompanyType(this.drawerConfig[0].ruleForm.companyCode);
                  } else if (val === '6') { // B类订单时,设置业务类型为B类，不可更改
                    this.setFormData('B', 'businessType');
                    this.getCompany(this.drawerConfig[0].ruleForm.custCode);
                  } else if (val === '7') { // 内部订单
                    this.getAllCompany();
                    this.getCompanyType(this.drawerConfig[0].ruleForm.companyCode);
                  }
                  if (val !== '2') {
                    this.setFormItemValue(['orderContactName', 'contactPhone'], 'disabled', true); // 客户信息不可以编辑
                  }
                  if (val !== '3') { // 不为促销订单，清空余额，关联活动信息
                    this.setFormData(null, 'actCash');
                    this.setFormData(null, 'relateActName');
                    this.setFormData(null, 'relateActCode');
                    this.setFormItemValue(['actCash'], 'show', false);
                  }
                },
              },
              {
                valueType: 'select',
                label: '公司主体',
                key: 'companyCode',
                options: {
                  label: 'companyName',
                  value: 'companyCode',
                },
                fn: (value, formData, ref) => {
                  this.$nextTick(() => {
                    const { ruleForm } = this.drawerConfig[0];
                    //  免费，内部订单，促销品都不用查资金账户余额
                    if (ruleForm.orderType !== '5' && ruleForm.orderType !== '7' && ruleForm.orderType !== '3') {
                      this.searchBalance();
                    }
                    this.setFormData(ref[0].selectedLabel, 'companyName');
                    this.getCompanyType(value);
                    this.deleteProduct('all', 1);
                    // 清空关联活动信息
                    this.clearActInfo();
                    // 获取销售渠道下拉
                    // this.filterSaleChannelOptions();
                  });
                },
              },
              {
                label: '业务类型',
                key: 'businessType',
                valueType: 'select',
                path: 'code?dictTypeCode=business_type',
                disabled: true,
              },

              {
                label: '负责人',
                key: 'responseName',
                valueType: 'iconInput',

                icon: 'el-icon-circle-plus-outline',
                fn: () => {
                  this.searchResponeseAll({});
                },
              },
              {
                label: '职位',
                key: 'responsePosName',
                disabled: true,
                show: true,
              }, {
                label: '业务部门',
                key: 'responseOrgName',
                valueType: 'input',
                show: true,
                disabled: true,
              },

              {
                label: '销售渠道',
                // data: [],
                key: 'saleChannel',
                valueType: 'select',
                path: 'code?dictTypeCode=dms_sales_channel',
                // path: 'code?dictTypeCode=dms_channel',
                show: true,
                disabled: false,
              },
              {
                label: '渠道类型',
                // data: [],
                key: 'channelType',
                valueType: 'select',
                path: 'code?dictTypeCode=dms_channel',
                show: true,
                disabled: false,
              },
              {
                label: '关联活动编码',
                key: 'relateActCode',
                valueType: 'input',
                show: false,
                disabled: true,
              },
              {
                label: '关联活动',
                key: 'relateActName',
                valueType: 'iconInput',
                icon: 'el-icon-circle-plus-outline',
                fn: () => {
                  this.searchAct({});
                },
                show: false,
              },
              {
                valueType: 'input',
                label: '活动余额（元）',
                key: 'actCash',
                disabled: true,
                show: false,
              },
              {
                label: '备注',
                key: 'remarks',
                itemWidth: '100%',
                valueType: 'textarea',
              }, {
                label: '附件',
                key: 'fileVos',
                itemWidth: '100%',
                valueType: 'inputUpload',
                uploadConfig: {
                  accept: 'pdf,docx,xlsx,ppt,png,jpg',
                },
              }, {
                valueType: 'text',
                text: '发运信息',
                labelWidth: '0',
                itemWidth: '100%',
              }, {
                valueType: 'select',
                label: '收货地址',
                key: 'contactAddress',
                itemWidth: '100%',
                options: {
                  value: 'id',
                  label: 'detailedAddress',
                },
                fn: (val, formData, r, d) => {
                  this.$nextTick(() => {
                    d.forEach((i) => {
                      // eslint-disable-next-line no-unused-expressions
                      i.detailedAddress === r[0].selectedLabel && this.setFormData(i.contactName, 'orderContactName');
                    });
                    d.forEach((i) => {
                      // eslint-disable-next-line no-unused-expressions
                      i.detailedAddress === r[0].selectedLabel && this.setFormData(i.contactPhone, 'contactPhone');
                    });
                    // this.setFormData(val, 'contactPhone');
                    this.setFormData(r[0].selectedLabel, 'contactAddress');
                  });
                },
              }, {
                label: '客户收货联系人',
                key: 'orderContactName',
                disabled: true,
              }, {
                label: '联系电话',
                key: 'contactPhone',
                disabled: true,
              }, {
                label: '发货时间',
                key: 'deliverDate',
                valueType: 'date',
              }, {
                label: '发货仓库',
                key: 'deliverStorage',
                show: false,
              }, {
                valueType: 'select',
                label: '是否报车',
                key: 'isHaveCar',
                path: 'code?dictTypeCode=yesOrNo',
              }, {
                label: '报车时间',
                key: 'carDate',
                valueType: 'date',
                disabled: true,
              }, {
                label: '驾驶证号',
                key: 'driverLicense',
              }, {
                label: '承运人',
                key: 'carrier',
              }, {
                label: '承运人电话',
                key: 'carrierPhone',
              }, {
                label: '承运人车号',
                key: 'carNum',
              },
              {
                valueType: 'text',
                text: '开票信息',
                labelWidth: '0',
                itemWidth: '100%',
              }, {
                valueType: 'select',
                label: '开票方式',
                key: 'invoiceType',
                path: 'code?dictTypeCode=invoice_type',
              }, {
                label: '开票时间',
                key: 'invoiceDate',
                valueType: 'date',
              }, {
                label: '开票人',
                key: 'invoicerName',
              },
              {
                valueType: 'text',
                text: '状态',
                labelWidth: '0',
                itemWidth: '100%',
                key: 'state',
                show: false,
              }, {
                label: '需货申请状态',
                key: 'applyStatus',
                valueType: 'select',
                path: 'code?dictTypeCode=apply_status',
                show: false,
              }, {
                valueType: 'select',
                label: '是否已扫码',
                path: 'code?dictTypeCode=yesOrNo',
                key: 'isScan',
                show: false,
              },
              ],
            ],
          },
          rules: { // 弹窗表单验证配置
            custCode: [
              {
                required: true,
                message: '请选择客户',
                trigger: 'change',
              },
            ],
            responseCode: [
              {
                required: true,
                message: '请选择负责人',
                trigger: 'change',
              },
            ],
            custType: [
              {
                required: true,
                message: '请选择客户类型',
                trigger: 'change',
              },
            ],

            // saleChannel: [
            //   {
            //     required: true,
            //     message: '请选择销售渠道',
            //     trigger: 'change',
            //   },
            // ],
            orderType: [
              {
                required: true,
                message: '请选择订单类型',
                trigger: 'change',
              },
            ],
            companyCode: [
              {
                required: true,
                message: '请选择公司主体',
                trigger: 'change',
              },
            ],
            orderContactName: [
              {
                required: true,
                message: '请输入客户收货联系人',
                trigger: 'blur',
              },
            ],
            contactAddress: [
              {
                required: true,
                message: '请选择收货地址',
                trigger: 'change',
              },
            ],
            invoiceType: [
              {
                required: true,
                message: '请选择开票方式',
                trigger: 'change',
              },
            ],
            saleChannel: [
              {
                required: true,
                message: '请选择销售渠道',
                trigger: 'change',
              },
            ],
            channelType: [
              {
                required: true,
                message: '请选择渠道类型',
                trigger: 'change',
              },
            ],
          },
        }, {
          title: '商品信息',
          type: 'table',
          tableConfig: {
            disabled: false,
            rows: [
              {
                label: '行号',
                prop: 'orderLineCode',
                width: 150,
                fixed: true,
              },
              {
                label: '商品编号',
                prop: 'productCode',
                width: 150,
                fixed: true,
              },
              {
                label: '商品名称',
                prop: 'productName',
                width: 250,
                fixed: true,
              }, {
                label: '规格',
                prop: 'spec',
                width: 100,
              }, {
                label: '类型',
                prop: 'productType',
                type: 'select',
                data: undefined,
                path: 'code?dictTypeCode=dms_product_type',
                disabled: true,
                width: 120,
              },
              {
                label: '数量',
                prop: 'num',
                type: 'input',
                inputType: 'number',
                width: 120,
                fn: async (row) => {
                  const { orderType } = this.drawerConfig[0].ruleForm;
                  this.$set(row, 'ext21', row.ext17);
                  this.$set(row, 'isQuota', 'N');
                  if (row.ext17 === '1') {
                    const params = {
                      companyCode: this.drawerConfig[0].ruleForm.companyCode,
                      customerCode: this.drawerConfig[0].ruleForm.custCode,
                      num: row.num,
                      productCode: row.productCode,
                      customerType: this.drawerConfig[0].ruleForm.custType,
                      saleChannel: this.drawerConfig[0].ruleForm.saleChannel,
                      channelType: this.drawerConfig[0].ruleForm.channelType,
                    };
                    const company = await this.$http.post(
                      '/dms/dms/contract/queryPriceSettingInfo', params,
                    );
                    const data = {
                      customerCode: this.drawerConfig[0].ruleForm.custCode,
                      productCode: row.productCode,
                      companyCode: company.result?.companyCode,
                      companyName: company.result?.companyName,
                      seriesCode: row.productLevelCode,
                    };
                    const balance = await this.$http.get(
                      '/dms/dms/customerQuota/findCustomerInfo', data,
                    );
                    this.$set(row, 'quotaCode', balance.result.quotaCode);
                    if (balance.result.productOrSeries === 'product') {
                      this.$set(row, 'balanceAmount', balance.result.balanceAmount);
                      if (balance.result.balanceAmount <= 0) {
                        this.$set(row, 'isQuota', 'Y');
                        this.searchQuotaPrice(row);
                      } else {
                        if (row.num > row.balanceAmount) {
                          this.$message.error('配额商品数量不能大于配额余量');
                          this.$set(row, 'num', row.balanceAmount);
                        }
                        this.searchPrice(row);
                      }
                    } else if (balance.result.productOrSeries === 'series') {
                      this.$set(row, 'balanceMoney', balance.result.balanceMoney);
                      if (balance.result.balanceMoney <= 0) {
                        this.$set(row, 'isQuota', 'Y');
                        this.searchQuotaPrice(row);
                      } else {
                        if (Number(row.num) * Number(row.productPrice) > Number(row.balanceMoney)) {
                          this.$message.error('配额商品总金额不能大于配额余额');
                          this.$set(row, 'num', Math.floor(Number(row.balanceMoney) / Number(row.productPrice)));
                        }
                        this.searchPrice(row);
                      }
                    }
                  } else if (orderType === '3' || orderType === '2' || orderType === '4') { // 促销品 处理品  货补 寻基本价
                    this.searchBasicPrice(row, orderType);
                  } else if (orderType === '6' || orderType === '1') { // B类  || 销售订单询价合同价格
                    this.searchPrice(row);
                  }
                },
              },
              {
                label: '配额余量',
                prop: 'balanceAmount',
                width: 120,
              },
              {
                label: '配额余额',
                prop: 'balanceMoney',
                width: 120,
              },
              {
                label: '单价（盒/瓶）',
                prop: 'productPrice',
                width: 120,
              }, {
                label: '折后价',
                prop: 'discountAfterPrice',
                width: 120,
              }, {
                label: '总金额（元）',
                prop: 'productTotalPrice',
                width: 120,
              }, {
                label: '资金池',
                prop: 'captitalItemAmount',
                width: 120,
                show: false,
              }, {
                label: 'B类资金池',
                prop: 'bcaptitalItemAmount',
                width: 120,
                show: false,
              }, {
                label: '实际支付',
                prop: 'actualItemAmount',
                width: 120,
                show: false,
              }, {
                label: '扫码入库数量',
                prop: 'enterNum',
                width: 120,
                show: false,
              }, {
                label: '比例',
                prop: 'enterRatio',
                width: 120,
                show: false,
              }, {
                label: '渠道费用(总)',
                prop: 'totalChannelFee',
                width: 120,
                show: false,
              }, {
                label: '折让费用(总)',
                prop: 'totalDiscountFee',
                width: 120,
                show: false,
              },
              {

                label: '发货状态',
                prop: 'orderDetailStatus',
                width: 120,
                type: 'select',
                data: undefined,
                //  key: 'orderDetailStatus',
                //  valueType: 'select',
                // data: null,
                // options: {
                //   label: 'orderStatusName',
                //   value: 'orderType',
                // },
                path: 'code?dictTypeCode=dnstatus',
              },
              {
                label: '渠道费用方式',
                prop: 'discountType',
                width: 120,
                show: false,
              }, {
                label: '渠道费用值',
                prop: 'discount',
                width: 120,
                show: false,
              },
            ],
            rowButtons: {
              items: [{
                type: 'default',
                icon: 'el-icon-delete',
                fn: (row, index) => {
                  this.deleteProduct(index, 1);
                },
              }],
            },
          },
          tableData: [],
          tableButtons: [
            {
              title: '添加商品',
              fn: () => {
                const { orderType } = this.drawerConfig[0].ruleForm;
                const productUrl = '/dms/dms/availableGoods/availableGoodsList';
                this.tableDataProduct = [];
                if (orderType === '3') { // 订单类型为促销品，选择所有商品
                  this.productUrl = '/mdm/mdmMaterialExtController/pageList';
                  this.searchProductAll({
                    excludeTypeList: ['产成品'],
                    enableStatus: '009',
                  });
                } else if (orderType === '6') { // B类订单
                  this.productUrl = productUrl;
                  this.searchProduct({
                    enableStatus: '009',
                  });
                  this.productType = '1';
                } else if (orderType === '5') { // 免费订单的
                  this.productUrl = '/mdm/mdmMaterialExtController/pageList';
                  this.searchProductAll({
                    enableStatus: '009',
                  });
                } else if (orderType === '2') { // 处理品
                  this.productUrl = '/mdm/mdmMaterialExtController/pageList';
                  this.searchProductAll({
                    enableStatus: '009',
                  });
                } else if (orderType === '7') { // 内部订单
                  this.productUrl = '/mdm/mdmMaterialExtController/pageList';
                  this.searchProductAll({
                    enableStatus: '009',
                  });
                } else if (orderType === '4') { // 货补订单
                  this.productUrl = productUrl;
                  this.productType = '0';
                  this.searchProduct({
                    enableStatus: '009',
                  });
                } else if (orderType === '1') { // 销售订单
                  this.productUrl = productUrl;
                  this.searchProduct({});
                }
              },
            }, {
              title: '添加货补池',
              show: false,
              fn: () => {
                this.productUrl = '/dms/dms/repPool/repPoolList';
                this.tableDataProduct = [];
                this.searchProduct({});
                this.productType = '2'; // 货补池商品类型为 {2: 货补(商品)}
              },
            }, {
              title: '清空',
              fn: () => {
                this.deleteProduct('all', 1);
                this.closeCapital();
              },
            },
          ],
          slotName: 'table-text',
        },
      ],

      //  ----------------------- 弹窗 - 商品列表配置
      drawerShowProduct: false,
      tableLoadingProduct: false,
      tablePaginationProduct: {},
      tableDataProduct: [{}],
      tableConfigProduct: {
        checkbox: true,
        isSaveSelect: true,
        checkboxFn: this.selectData,
        rows: [{
          label: '商品编码',
          prop: 'productCode',
        },
        {
          label: '商品名称',
          prop: 'productName',
        },
        {
          label: '规格',
          prop: 'spec',
        }, {
          label: '单位',
          prop: 'saleUnitName',
        }],
      }, // 列表配置
      searchConfigProduct: {
        options: [
          {
            valueName: 'productCode',
            placeholder: '请输入商品编号',
          }, {
            valueName: 'productName',
            placeholder: '请输入商品名称',
          },
        ],
      },

      //  ----------------------- 弹窗 - 客户列表配置
      drawerShowCustomer: false,
      tableLoadingCustomer: false,
      tableDataCustomer: [],
      tableConfigCustomer: {
        checkbox: true,
        checkboxFn: this.selectData,
        rows: [
          {
            label: '客户编码',
            prop: 'customerCode',
          },
          {
            label: '客户名称',
            prop: 'customerName',
          },
          {
            label: '客户类型',
            prop: 'customerTypeName',
          },
        ],
      },
      searchConfigCustomer: {
        options: [
          {
            valueName: 'customerName',
            placeholder: '请输入客户名称',
          }, {
            valueName: 'customerCode',
            placeholder: '请输入客户编码',
          }, {
            valueName: 'customerType',
            placeholder: '请选择客户类型',
            valueType: 'select',
            path: 'code?dictTypeCode=cust_type',
          },
        ],
      },
      // 关联活动弹窗
      drawerShowAct: false,
      tableLoadingAct: false,
      tableDataAct: [],
      tableConfigAct: {
        checkbox: true,
        checkboxFn: this.selectData,
        rows: [
          {
            label: '活动编码',
            prop: 'actDetailCode',
          },
          {
            label: '活动名称',
            prop: 'ext1',
          },
          {
            label: '活动金额（元）',
            prop: 'applyAmount',
          },
        ],
      },
      searchConfigAct: {
        options: [
          {
            valueName: 'actDetailCode',
            placeholder: '请输入活动编码',
          }, {
            valueName: 'ext1',
            placeholder: '请输入活动名称',
          },
        ],
      },
      paginationAct: { total: 0 },

      //  ----------------------- 弹窗 - 负责人列表配置
      drawerShowResponese: false,
      tableLoadingResponese: false,
      tableDataResponese: [],
      tableConfigResponese: {
        checkbox: true,
        checkboxFn: this.selectData,
        rows: [
          {
            label: '负责人编码',
            prop: 'responseCode',
          },
          {
            label: '负责人名称',
            prop: 'responseName',
          },

        ],
      },
      searchConfigResponese: {
        options: [
          {
            valueName: 'fullName',
            placeholder: '请输入负责人名称',
          }, {
            valueName: 'userName',
            placeholder: '请输入负责人编码',
          },
        ],
      },
      pagination: { total: 0 },

      //  ----------------------- 弹窗 - 开票人列表配置
      drawerShowTicket: false,
      tableLoadingTicket: false,
      tableDataTicket: [],
      tableConfigTicket: {
        checkbox: true,
        checkboxFn: this.selectData,
        rows: [
          {
            label: '用户姓名',
            prop: 'fullName',
          }, {
            label: '用户编码',
            prop: 'userName',
          },
          {
            label: '所属组织',
            prop: 'positionName',
          },
        ],
      },
      searchConfigTicket: {
        options: [
          {
            valueName: 'fullName',
            placeholder: '请输入姓名',
          }, {
            valueName: 'roleName',
            placeholder: '请输入角色',
          }, {
            valueName: 'positionName',
            placeholder: '请输入业务部门',
          },
        ],
      },
      saleChannelOptions: [],
    };
  },
  created() {
    this.initPageState();
    // this.getSaleChannelOptions();
    /**
     * 以下两行未了处理
     * bpm待办事项引入组件查询详情 （审批流）
     */
    this.code = this.dmsCode || this.code;
    console.log(this.code);
    this.id = this.dmsId || this.id;
    if (this.code === 'view') {
      this.getDetailType();
      //  this.getOrderDetailStatus();
    } else {
      this.getOrderType();
    }
  },

  watch: {
    id(val) {
      if (val) {
        const itemFormNames = ['orderCode', 'state', 'isScan', 'applyStatus', 'responseBranchName', 'createDate'];
        const names = ['captitalItemAmount', 'bcaptitalItemAmount', 'actualItemAmount',
          'totalChannelFee', 'totalDiscountFee', 'discountType', 'discount', 'enterNum', 'enterRatio'];
        this.setDrawerTable('商品信息', names, 'show', true);
        this.setFormItemValue(itemFormNames, 'show', true);
        this.searchDetail();
      }
    },
  },

  methods: {
    // getSaleChannelOptions() {
    //   this.$http.post(
    //     '/mdm/mdmdictdata/list', { dictTypeCode: 'dms_sales_channel' },
    //   ).then((res) => {
    //     this.saleChannelOptions = res.result;
    //   });
    // },
    // async filterSaleChannelOptions() {
    //   this.setFormData(null, 'saleChannel');
    //   const formData = this.drawerConfig[0].ruleForm;
    //   if (!formData.companyCode || !formData.custCode) {
    //     return false;
    //   }
    //   const params = {
    //     companyCode: formData.companyCode,
    //     cusCode: formData.custCode,
    //   };
    //   const { result } = await this.$http.post(
    //     '/dms/dms/contract/querySaleChannel', params,
    //   );
    //   const options = [];
    //   result.forEach((item) => {
    //     this.saleChannelOptions.forEach((v) => {
    //       if (v.dictCode === item) {
    //         options.push(v);
    //       }
    //     });
    //   });
    //   this.$set(this.drawerConfig[0].formConfig.items[0][9], 'data', options);
    // },
    // 配额询价
    async searchQuotaPrice(row) {
      const params = {
        companyCode: this.drawerConfig[0].ruleForm.companyCode,
        customerCode: this.drawerConfig[0].ruleForm.custCode,
        num: row.num,
        productCode: row.productCode,
        customerType: this.drawerConfig[0].ruleForm.custType,
        saleChannel: this.drawerConfig[0].ruleForm.saleChannel,
      };
      this.$http.post(
        '/dms/dms/contract/queryPriceSettingInfo', params,
      ).then((res) => {
        if (res.success) {
          this.$nextTick(() => {
            const { discountAfterPrice, quotaPrice } = res.result;
            const productTotalPrice = _.multiply(Number(row.num || 0), Number(quotaPrice || 0)).toFixed(2);
            this.$set(row, 'productTotalPrice', productTotalPrice);
            this.$set(row, 'productPrice', quotaPrice);
            this.$set(row, 'discountAfterPrice', discountAfterPrice);
            this.$set(row, 'discountAfterPrice', discountAfterPrice);
            this.calculateReplenishCaptital();
            this.totalAmount();
          });
        }
      });
    },

    initPageState() {
      const itemFormNames = ['orderCode', 'state', 'isScan', 'applyStatus', 'responseBranchName', 'createDate'];
      if (this.code === 'view') { // 设置查看显示的字段
        this.setFormItemValue(itemFormNames, 'show', true);
        const names = ['captitalItemAmount', 'bcaptitalItemAmount', 'actualItemAmount',
          'totalChannelFee', 'totalDiscountFee', 'discountType', 'discount', 'enterNum', 'enterRatio'];
        this.setDrawerTable('商品信息', names, 'show', true);
      } else {
        this.setFormItemValue(itemFormNames, 'show', false);
      }
      if (this.code === 'edit') {
        this.setFormItemValue(['orderType'], 'disabled', true); // 订单类型编辑不可编辑
      }
      if (this.code !== 'add') { // 非新增查询详情
        this.searchDetail();
      }
    },

    /**
     * 报车
     * */
    async carReport() {
      try {
        this.loading = true;
        await this.$http.get('/dms/dms/order/carReport', { id: this.drawerConfig[0].ruleForm.id });
        this.$message.success('报车成功');
        this.loading = false;
        this.formShow = false;
        this.getParentTable('need-goods-list');
      } catch (error) {
        this.loading = false;
      }
    },

    /**
     * 设置禁用
     * @param result {<void>}
     * @param state {boolean}
     */
    setDisabled(state, result) {
      this.$nextTick(() => {
        this.drawerConfig[0].formConfig.disabled = state;
        this.drawerConfig[1].tableConfig.disabled = state;
        this.drawerConfig[1].tableConfig.rowButtons.show = !state;
        this.drawerButton.show = !state;
      });
    },

    /**
     * 查询详情
     */
    async searchDetail() {
      if (this.id || this.orderCode) {
        const { result } = await this.$http.post('/dms/dms/order/query', {
          orderCode: this.orderCode,
          id: this.id,
        });
        console.log(result);
        if (result.discountType === '1') {
          result.discount = result.discountRate;
        } else {
          result.discount = result.discountPrice;
        }
        delete result.pageSize;
        delete result.pageNum;
        this.drawerConfig[0].ruleForm = { ...result };
        const compare = function (obj1, obj2) {
          const val1 = obj1.orderLineCode;
          const val2 = obj2.orderLineCode;
          if (val1 < val2) {
            return -1;
          } if (val1 > val2) {
            return 1;
          }
          return 0;
        };
        result.detailList = result.detailList.map((v) => {
          const obj = v;
          obj.ext17 = obj.ext21;
          if (result.hide && this.code === 'view') {
            obj.productPrice = '****';// 单价（盒/瓶）
            obj.discountAfterPrice = '****';// 折后价
            obj.productTotalPrice = '****';// 总金额（元）
            obj.captitalItemAmount = '****';// 资金池
            obj.bcaptitalItemAmount = '****';// B类资金池
            obj.actualItemAmount = '****';// 实际支付
            obj.totalChannelFee = '****';// 渠道费用（总）
            obj.totalDiscountFee = '****';// 折让费用（总）
            obj.discount = '****';// 渠道费用值
          }
          return obj;
        });
        this.drawerConfig[1].tableData = result.detailList.sort(compare);
        this.setDisabled(this.code === 'view', result);
        this.selectOrderType(result.orderType, true);
        if (result.hide && this.code === 'view') {
          this.drawerConfig[0].ruleForm.accountAmountUseFulCash = '****';// 资金账户余额（元）
          this.drawerConfig[0].ruleForm.amount = '****';// 订单总金额（元）
          this.drawerConfig[0].ruleForm.shouldPayAmount = '****';// 应付合计（元）
          this.drawerConfig[0].ruleForm.actualAmount = '****';// 本单实际支付（元）
          this.drawerConfig[0].ruleForm.captitalAmount1 = '****';// 资金账户支付（元）
          this.$forceUpdate();
        } else {
          this.totalAmount();
          if (this.code === 'view') { // 详情不需要去查资金账户
            this.drawerConfig[0].ruleForm.accountAmountUseFulCash = result.usefulAmount;
            this.$forceUpdate();
          }
          //  免费，内部订单，促销品,都不用查资金账户余额
          if (result.orderType !== '5' && result.orderType !== '7' && result.orderType !== '3') {
            await this.searchBalance();
          }
        }
        if (result.orderType === '1') { // 销售订单
          this.getCompany(result.custCode);
          this.getCompanyType(result.companyCode);
        } else if (result.orderType === '2') { // 处理品 可以选择所有公司
          this.getAllCompany();
          this.getCompanyType(result.companyCode);
          this.setFormItemValue(['orderContactName', 'contactPhone'], 'disabled', false); // 客户信息可以编辑
        } else if (result.orderType === '6') { // B类订单时,设置业务类型为B类，不可更改
          this.setFormData('B', 'businessType');
          this.getCompany(result.custCode);
        } else if (result.orderType === '7' || result.orderType === '4' || result.orderType === '3' || result.orderType === '5') { // 内部订单 || 货补订单 || 促销品 || 免费订单
          this.getAllCompany();
          this.getCompanyType(result.companyCode);
        }

        await this.getAddress(result.custCode);
      }
    },

    /**
     * 查询收货地址
     * @param customerCode {string}
     * */
    async getAddress(customerCode) {
      const { result } = await this.$http.post('/mdm/mdmCustomerAddressController/listCondition', { customerCode });
      this.setFormItemValue(['contactAddress'], 'data', result);
    },

    /**
     * 查询客户（经销商）列表
     * @param requestData {Object}
     * @returns {Promise<void>}
     */
    async searchCustomer(requestData) {
      this.showDrawer('drawerShowCustomer');
      this.tableLoadingCustomer = true;
      const params = {
        pageSize: 10,
        ...requestData,
      };
      const { result } = await this.$http.post(
        '/mdm/mdmCustomerMsgExtController/findCustomerSelectListByUseSfa', params,
      );
      this.tableDataCustomer = result;
      this.tableLoadingCustomer = false;
    },

    /**
     * 根据订单类型查询商品
     * @param requestData {object} 查询传参
     * */
    selectSearchProduct(requestData) {
      const tempData = requestData;
      const { orderType } = this.drawerConfig[0].ruleForm;

      if (orderType === '5' || orderType === '3' || orderType === '2' || orderType === '7') {
        tempData.materialName = tempData.productName || '';
        tempData.materialCode = tempData.productCode || '';
        if (orderType === '3') {
          tempData.excludeTypeList = ['产成品'];
        }
        this.searchProductAll(tempData);
      } else {
        this.searchProduct(tempData);
      }
    },
    /**
     * 查询商品 -- 合同商品列表
     * @param requestData {Object}
     * @param url {string}
     * @returns {Promise<void>}
     */
    async searchProduct(requestData) {
      const formData = this.drawerConfig[0].ruleForm;
      this.showDrawer('drawerShowProduct'); // 打开弹窗
      this.tableLoadingProduct = true; // 打开loading
      const requestUrl = this.productUrl; // 设置地址
      const productCodes = this.drawerConfig[1].tableData.map((i) => {
        if (requestUrl === '/dms/dms/repPool/repPoolList' && i.productType === '2') {
          return i.productCode;
        }
        return i.productCode;
      });
      const params = {
        pageSize: 10,
        ...requestData,
        ...formData,
        excludeProductCodeList: productCodes,
        excludeCodeList: productCodes,
        enableStatus: '009',
      };
      params.cusCode = params.custCode;
      const { result, result: { data, count } } = await this.$http.post(requestUrl, params);
      this.tableDataProduct = requestUrl === '/dms/dms/repPool/repPoolList' ? result : data || [];
      this.tablePaginationProduct.total = count;
      this.tableLoadingProduct = false;
      await this.searchBalance();
    },

    /**
     * 查询所有商品
     * */
    async searchProductAll(requestData) {
      const params = {
        pageSize: 10,
        materialCode: requestData?.productCode,
        materialName: requestData?.productName,
        ...requestData,
        enableStatus: '009',
      };
      this.showDrawer('drawerShowProduct');
      const { result: { data, count } } = await this.$http.post(
        '/mdm/mdmMaterialExtController/pageList', params,
      );

      this.tablePaginationProduct.total = count;
      this.tableDataProduct = data;
      this.tableLoadingProduct = false;
      // await this.searchBalance();
    },

    /**
     * 查询开票人列表
     * @param requestData {Object}
     * @returns {Promise<void>}
     */
    async searchTicket(requestData) {
      this.showDrawer('drawerShowTicket');
      this.tableLoadingTicket = true;
      const params = {
        ...requestData,
        customerType: 'u',
      };
      const { result } = await this.$http.post(
        '/mdm/mdmUserController/list_detail', params,
      );
      this.tableDataTicket = result;
      this.tableLoadingTicket = false;
    },

    /**
     * 查询开票人信息
     * */
    async searchTicketDetail(customerCode) {
      const { result } = await this.$http.post(
        '/mdm/mdmCustomerMsgExtController/findFinancialInformationByCustomerCode',
        { customerCode },
        {
          headers: {
            ContentType: 'application/x-www-form-urlencoded;charset=UTF-8',
          },
        },
      );
      this.setFormData(this.selectTableData[0].financeContact, 'invoicerName'); // 开票人
    },

    /**
     * 获取公司主体类型
     * @param companyCode {string}
     * @returns {Promise<void>}
     * */
    async getCompanyType(companyCode) {
      if (!companyCode) {
        this.setFormData(null, 'businessType');
        return;
      }
      const { result } = await this.$http.post('/mdm/companyInfo/queryDetail', { companyCode }, {
        headers: {
          ContentType: 'application/x-www-form-urlencoded;charset=UTF-8',
        },
      });
      if (this.drawerConfig[0].ruleForm.orderType === '6') { // B类订单时,设置业务类型为B类，不可更改
        this.setFormData('B', 'businessType');
      } else {
        this.setFormData(result.companyType, 'businessType');
      }
    },

    /**
     * 查询负责人
     * @param customerCode {string}
     * @returns {Promise<void>}
     * */
    async searchDocking(customerCode) {
      if (!customerCode) return;
      const { result } = await this.$http.post('/mdm/mdmCustomerMsgExtController/findDockingListByCustomerCode', { customerCode }, {
        headers: {
          ContentType: 'application/x-www-form-urlencoded;charset=UTF-8',
        },
      });
      this.setFormItemValue(['responseCode'], 'data', result);
    },

    /**
     * 查询所有负责人
     * @param requestData {Object<void>}
     * @returns {Promise<void>}
     * */
    async searchResponeseAll(requestData) {
      this.showDrawer('drawerShowResponese');
      let tempResult = [];
      this.tableLoadingResponese = true;
      const params = {
        pageSize: 10,
        ...requestData,
      };
      if (this.drawerConfig[0].ruleForm.orderType === '2') { // 处理品，选择所有负责人
        const res = await this.$http.post(
          '/mdm/mdmUserExtController/pageInfoList', params,
        );
        tempResult = res.result.data;
        this.pagination.total = res.result.count;
      } else {
        if (!this.drawerConfig[0].ruleForm.custCode) return;
        const { result } = await this.$http.post('/mdm/mdmCustomerMsgExtController/findDockingListByCustomerCode', { customerCode: this.drawerConfig[0].ruleForm.custCode }, {
          headers: {
            ContentType: 'application/x-www-form-urlencoded;charset=UTF-8',
          },
        });
        tempResult = result;
        this.pagination.total = result.length;
      }
      const tempArr = [];
      tempResult.forEach((item) => {
        tempArr.push({
          responseCode: item.userName,
          responseName: item.fullName,
          responsePosCode: item.positionCode,
          responsePosName: item.positionName,
          responseOrgName: item.orgName,
          responseOrgCode: item.orgCode,
        });
      });
      this.tableDataResponese = tempArr;
      this.tableLoadingResponese = false;
    },

    // 查询关联活动
    async searchAct(requestData) {
      const { custCode, responseOrgCode, companyCode } = this.drawerConfig[0].ruleForm;
      if (!custCode) {
        this.$message.error('请先选择客户');
        return;
      }
      if (!companyCode) {
        this.$message.error('请先选择公司主体');
        return;
      }
      if (!responseOrgCode) {
        this.$message.error('请先选择负责人');
        return;
      }

      this.showDrawer('drawerShowAct');
      let tempResult = [];
      this.tableLoadingAct = true;
      const params = {
        pageSize: 10,
        ...requestData,
        customerCode: custCode,
        orgCode: responseOrgCode,
        companyCode,
      };

      const res = await this.$http.post(
        '/tpm/tpmActExt/actDetail', params,
      );
      tempResult = res.result.data;
      this.paginationAct.total = res.result.count;

      this.tableDataAct = tempResult;
      this.tableLoadingAct = false;
    },

    /**
     * 查询所有公司主体
     * @param custCode {string}
     * @returns {Promise<void>}
     * */
    async getAllCompany() {
      const { result } = await this.$http.post('/mdm/companyInfo/companyPageList', { pageSize: -1 });
      this.setFormItemValue(['companyCode'], 'data', result.data);
    },

    /**
     * 查询公司主体
     * @param custCode {string}
     * @returns {Promise<void>}
     * */
    async getCompany(custCode) {
      if (!custCode) {
        return;
      }
      const { result } = await this.$http.post('/dms/dms/contract/usefulCompanyList', { custCode, businessType: this.drawerConfig[0].ruleForm.businessType });
      this.setFormItemValue(['companyCode'], 'data', result);
    },

    /**
     * 查询公司主体
     * @param custCode {string}
     * @returns {Promise<void>}
     * */
    async getOrderType() {
      const { result } = await this.$http.post('/dms/dms/order/orderTypeList', {});
      const tempArr = [];
      for (const item of result) {
        tempArr.push({
          orderType: item.orderTypeCode,
          orderTypeName: item.orderTypeName,
        });
      }
      this.setFormItemValue(['orderType'], 'data', tempArr);
    },

    /**
     * 详细  获取订单类型
     * */
    async getDetailType() {
      const { result } = await this.$http.post('/mdm/mdmdictdata/list', { dictTypeCode: 'dms_order_type' });
      const tempArr = [];
      for (const item of result) {
        tempArr.push({
          orderTypeName: item.dictValue,
          orderType: item.dictCode,
        });
      }
      this.setFormItemValue(['orderType'], 'data', tempArr);
    },
    /**
     * 详细  商品信息 发货状态
     */
    // async getOrderDetailStatus() {
    //   const { result } = await this.$http.post('/mdm/mdmdictdata/list', { dictTypeCode: 'dnstatus' });
    //   const tempArr = [];
    //   for (const item of result) {
    //     tempArr.push({
    //       orderStatusName: item.dictValue,
    //       orderType: item.dictCode,
    //     });
    //   }
    //   this.setFormItemValue(['orderDetailStatus'], 'data', tempArr);
    //   console.log('------------------', tempArr);
    // },
    /**
     * 选择列表数据
     * @param data {Array}
     * @returns {boolean}
     */
    selectData(data) {
      this.selectTableData = data;
    },

    /**
     * 关闭弹窗
     * @param name {string}
     */
    closeDrawer(name) {
      this[name] = false;
    },

    /**
     * 显示列表弹窗 清楚选中的数据
     * @param name {string}
     */
    showDrawer(name) {
      this[name] = true;
      this.selectTableData = [];
    },

    /**
     * 显示表单部分字段
     * @param names {Array<string>} 需要改变的key的集合
     * @param keyName {string} 修改的字段
     * @param data <void> 修改的值
     */
    setFormItemValue(names, keyName, data) {
      this.drawerConfig.forEach((k) => {
        if (k.type === 'form') {
          k.formConfig.items[0].map((i) => {
            if (names.includes(i.key)) {
              if (i[keyName]) {
                i[keyName] = data;
              } else {
                this.$set(i, keyName, data);
              }
              return i;
            }
            return i;
          });
        }
      });
    },

    /**
     * 区分弹窗列表 - 处理对应的数据
     */
    beforeSaveSelect() {
      // 开票人
      if (this.drawerShowTicket === true && !this.selectMessage()) {
        this.saveSelectTicket();
        this.closeDrawer('drawerShowTicket');
      }

      // 客户
      if (this.drawerShowCustomer === true && !this.selectMessage()) {
        this.saveSelectCustomer();
        this.closeDrawer('drawerShowCustomer');
      }

      if (this.drawerShowProduct === true) { // 商品
        this.saveProduct();
        return true;
      }

      if (this.drawerShowResponese === true) { // 负责人
        this.saveResponese();
        this.closeDrawer('drawerShowResponese');
        return true;
      }
      if (this.drawerShowAct === true) { // 关联活动
        this.saveAct();
        this.closeDrawer('drawerShowAct');
        return true;
      }
    },

    /**
     * 弹窗保存验证提示
     */
    selectMessage() {
      if (this.selectTableData.length > 1) {
        this.$message.error('只能选择一条数据');
        return true;
      }
      return false;
    },

    /**
     * 保存开票人
     */
    saveSelectTicket() {
      this.setFormData(this.selectTableData[0].userName, 'invoicerCode');
      this.setFormData(this.selectTableData[0].fullName, 'invoicerName');
    },

    /**
     * 保存客户
     */
    saveSelectCustomer() {
      this.setFormData(this.selectTableData[0].customerCode, 'custCode'); // 客户名称code
      this.setFormData(this.selectTableData[0].customerName, 'custName'); // 客户名称
      this.setFormData(this.selectTableData[0].customerType, 'custType'); // 客户类型

      // 获取满足客户下有效合同的销售渠道
      // this.filterSaleChannelOptions();

      // 清空负责人信息
      // this.setFormItemValue(['responseCode'], 'data', []);
      this.setFormData(null, 'responseName');
      this.setFormData(null, 'responseCode');
      this.setFormData(null, 'responsePosCode');
      this.setFormData(null, 'responsePosName');
      this.setFormData(null, 'responseOrgName');
      this.setFormData(null, 'responseOrgCode');
      // 清空关联活动信息
      this.clearActInfo();

      this.setFormData('', 'contactAddress'); // 收货地址
      this.setFormData('', 'orderContactName'); // 客户收货联系人
      this.setFormData('', 'contactPhone'); //  联系电话
      this.getAddress(this.selectTableData[0].customerCode);
      // 业务类型为B类，需要去显示当前B类签订合同内公司主体
      if (this.drawerConfig[0].ruleForm.businessType === 'B') {
        this.getCompany(this.selectTableData[0].customerCode);
      }

      this.searchTicketDetail(this.selectTableData[0].customerCode);
      // 去查询资金账户（需要客户编码，公司主体)
      this.searchBalance();
    },

    // 保存负责人
    saveResponese() {
      this.$nextTick(() => {
        this.setFormData(this.selectTableData[0].responseCode, 'responseCode');
        this.setFormData(this.selectTableData[0].responseName, 'responseName');
        this.setFormData(this.selectTableData[0].responsePosCode, 'responsePosCode');
        this.setFormData(this.selectTableData[0].responsePosName, 'responsePosName');
        this.setFormData(this.selectTableData[0].responseOrgName, 'responseOrgName');
        this.setFormData(this.selectTableData[0].responseOrgCode, 'responseOrgCode');
        // 清空关联活动信息
        this.clearActInfo();
      });
    },
    // 保存活动
    saveAct() {
      this.$nextTick(() => {
        if (this.selectTableData.length > 0) {
          this.setFormData(this.selectTableData[0].actDetailCode, 'relateActCode');
          this.setFormData(this.selectTableData[0].ext1, 'relateActName');
          const leftMoney = _.subtract(Number(this.selectTableData[0].applyAmount || 0), Number(this.selectTableData[0].auditAmount || 0));
          this.setFormData(leftMoney, 'actCash');
          this.setFormItemValue(['actCash'], 'show', true);
        } else {
          this.setFormData(null, 'actCash');
          this.setFormData(null, 'relateActName');
          this.setFormData(null, 'relateActCode');
          this.setFormItemValue(['actCash'], 'show', false);
        }
      });
    },
    // 清空关联活动信息
    clearActInfo() {
      this.setFormData('', 'relateActName');
      this.setFormData('', 'relateActCode');
      this.setFormData(0, 'actCash');
      this.paginationAct.total = 0;
      this.tableDataAct = [];
    },
    // 查询余额
    async searchBalance() {
      const { orderType, custCode, companyCode } = this.drawerConfig[0].ruleForm;
      //  免费，内部订单，促销品,货补都不用查资金账户余额
      if (orderType !== '5' && orderType !== '7' && orderType !== '3') {
        if (custCode && companyCode) {
          const params = {
            ...this.drawerConfig[0].ruleForm,
          };
          params.cusCode = params.custCode;
          const { result } = await this.$http.post('/dms/captitalPoolController/queryByCondition', params);
          this.setFormData(result?.balance || 0, 'balance');
          this.setFormData(result?.accountAmountUseFulCash || 0, 'accountAmountUseFulCash');
        }
      }
    },

    // 保存选中的商品
    saveProduct() {
      const { orderType } = this.drawerConfig[0].ruleForm;
      this.selectTableData.map(async (i) => {
        i.productType = this.productType;
        let price;
        if (i.ext17 === '1') {
          // 配额商品查询配额剩余
          const params = {
            customerCode: this.drawerConfig[0].ruleForm.custCode,
            companyCode: this.drawerConfig[0].ruleForm.companyCode,
            productCode: i.productCode,
            seriesCode: i.productLevelCode,
          };
          const res = await this.$http.get('/dms/dms/customerQuota/findCustomerInfo', params);
          if (res.success) {
            if (res.result.productOrSeries === 'product') {
              this.$set(i, 'balanceAmount', res.result.balanceAmount);
            } else if (res.result.productOrSeries === 'series') {
              this.$set(i, 'balanceMoney', res.result.balanceMoney);
            }
          }
        }
        this.$set(i, 'num', '');
        if (orderType === '5') { // 免费订单
          this.$set(i, 'productTotalPrice', 0);
          this.$set(i, 'discountAfterPrice', 0);
        } else {
          this.$set(i, 'productTotalPrice', '');
        }
        this.$set(i, 'productCode', (i.materialCode || i.productCode));
        this.$set(i, 'productName', (i.materialName || i.productName));
        this.$set(i, 'spec', (i.specification || i.spec));
        this.$set(i, 'productPrice', 0);
        return i;
      });

      this.selectTableData = [...this.drawerConfig[1].tableData, ...this.selectTableData];
      this.setDrawerValue('商品信息', 'tableData', this.selectTableData);
      this.closeDrawer('drawerShowProduct');
      this.isProductType();
    },

    // 是否有货补商品
    isProductType() {
      const { tableData } = this.drawerConfig[1];
      const num = tableData.some((i) => i.productType === '2');
      this.captitalAmountDisable = num;
      if (num) {
        this.setFormData(0, 'captitalAmount'); // 资金池清空
        this.setFormData(0, 'bcaptitalAmount'); // b类资金
      }
    },

    // 计算账户金额（实际支付金额）
    calculateCaptital(name) {
      const { amount } = this.drawerConfig[0].ruleForm;
      this.setFormData(amount - this.drawerConfig[0].ruleForm[name], 'actualAmount');
    },

    // 计算货补金额
    calculateReplenishCaptital() {
      const { tableData } = this.drawerConfig[1];
      let supplementAmount = 0;
      tableData.forEach((i) => {
        if (i.productType === '2') {
          supplementAmount += Number(i.productTotalPrice);
        }
      });
      this.setFormData(supplementAmount, 'supplementAmount');
      this.calculateCaptital('supplementAmount');
    },

    // 清空资金信息
    closeCapital() {
      this.selectTableData = [];
      this.setDrawerValue('商品信息', 'tableData', []);
      this.resetCapital();
    },

    // 重置金额
    resetCapital() {
      this.setFormData(0, 'captitalAmount'); // 货补资金池清空
      this.setFormData(0, 'captitalAmount1'); // 资金池清空
      this.setFormData(0, 'bcaptitalAmount'); // b类资金
      this.setFormData(0, 'amount'); // 总金额
      this.setFormData(0, 'supplementAmount'); // 货补金额
      this.setFormData(0, 'accountAmountUseFulCash'); // 账户资金
      this.setFormData(0, 'actualAmount'); // 实际支付
      this.setFormData(0, 'shouldPayAmount'); // 应支付金额
    },
    // 两个浮点数求和
    accAdd(num1, num2) {
      let r1;
      let r2;
      let m = 0;
      try {
        r1 = num1.toString().split('.')[1].length;
      } catch (e) {
        r1 = 0;
      }
      try {
        r2 = num2.toString().split('.')[1].length;
      } catch (e) {
        r2 = 0;
      }
      // eslint-disable-next-line no-restricted-properties
      m = Math.pow(10, Math.max(r1, r2));
      // return (num1*m+num2*m)/m;
      return Math.round(num1 * m + num2 * m) / m;
    },
    // 提交表单数据
    async requestSave(data, temporaryFlag) {
      const { ruleForm } = this.drawerConfig[0];
      const { tableData } = this.drawerConfig[1];
      if (ruleForm.businessType === 'B') { // 业务类型为B类
        let amount = 0;
        tableData.forEach((i) => {
          amount = this.accAdd(amount, parseFloat(i.productTotalPrice));
          // amount += Number(i.productTotalPrice);
        });
        if (Number(ruleForm.balance) < amount) { // B类资金余额小于应付总金额
          this.$message.error('B类资金不足，不允许下单');
          return;
        }
        console.log(Number(ruleForm.accountAmountUseFulCash), amount);
        if (Number(ruleForm.accountAmountUseFulCash) < amount) { // 资金账户余额 小于应付金额
          this.$message.error('资金账户余额不足，不允许下单');
          return;
        }
      }
      if (tableData.length > 0) {
        const isNum = tableData.find((v) => v.num <= 0);
        if (isNum) {
          this.$message.error(`商品：${isNum.productName}数量需大于0`);
          return;
        }
      }
      const requestData = {
        ...ruleForm,
        detailList: tableData,
      };
      requestData.temporaryFlag = temporaryFlag || 'N';
      const url = this.code === 'edit' ? '/dms/dms/order/updateOrder' : '/dms/dms/order/saveOrder';
      this.loading = true;
      try {
        await this.$http.post(url, requestData);
        this.$message.success('保存成功');
        this.loading = false;
        this.formShow = false;
        this.getParentTable('need-goods-list');
      } catch (error) {
        this.loading = false;
      }
    },

    /**
     * 设置表单数据
     * @param data <void>
     * @param dataName {string}
     */
    setFormData(data, dataName) {
      this.$set(this.drawerConfig[0].ruleForm, dataName, data);
    },

    /**
     * 设置弹窗组件的配置
     * @param componentTitle {String}
     * @param valueName {string}
     * @param data <void>
     */
    setDrawerValue(componentTitle, valueName, data) {
      this.drawerConfig.map((item) => {
        if (item.title === componentTitle) {
          item[valueName] = data;
          return item;
        }
        return item;
      });
    },

    /**
     * 删除商品
     * @param index {number || String}
     * @param configIndex {number}
     */
    deleteProduct(index, configIndex) {
      if (index === 'all') {
        this.drawerConfig[configIndex].tableData = [];
        this.captitalAmountDisable = false;
      } else {
        this.drawerConfig[configIndex].tableData.splice(index, 1);
        this.isProductType();
        this.calculateReplenishCaptital();
        this.totalAmount();
      }
    },

    // 寻我们维护的价
    async searchPrice(row) {
      const params = {
        priceTypeEnum: 'P002',
        customerCode: this.drawerConfig[0].ruleForm.custCode,
        customerType: this.drawerConfig[0].ruleForm.custType,
        num: row.num,
        companyCode: this.drawerConfig[0].ruleForm.companyCode,
        saleChannel: this.drawerConfig[0].ruleForm.saleChannel,
        productCode: row.productCode,
      };
      const rowData = row;
      const { result, code, message } = await this.$http.post(
        '/dms/dms/contract/queryPriceSettingInfo', params,
      );
      if (code === 500) {
        this.$message.error(message);
        return;
      }
      this.$nextTick(() => {
        const { discountAfterPrice } = result;
        const productTotalPrice = _.multiply(Number(row.num || 0), Number(discountAfterPrice || 0)).toFixed(2);
        this.$set(row, 'productTotalPrice', productTotalPrice);
        this.$set(row, 'productPrice', result.discountAfterPrice);
        this.$set(row, 'discountAfterPrice', discountAfterPrice);
        this.calculateReplenishCaptital();
        this.totalAmount();
      });
    },

    // 寻基本价
    async searchBasicPrice(row, orderType) {
      let priceTypeEnum = '';
      let priceSettingType = '';
      if (orderType === '2' || orderType === '3') {
        priceTypeEnum = 'P002';
      } else if (orderType === '4') {
        priceTypeEnum = 'P001';
        priceSettingType = '04';
      }
      const params = {
        priceTypeEnum,
        priceSettingType,
        customerCode: this.drawerConfig[0].ruleForm.custCode,
        customerType: this.drawerConfig[0].ruleForm.custType,
        num: row.num,
        companyCode: this.drawerConfig[0].ruleForm.companyCode,
        saleChannel: this.drawerConfig[0].ruleForm.saleChannel,
        productCode: row.productCode,
        ext36: this.drawerConfig[0].ruleForm.saleChannel,
      };
      const rowData = row;
      const res = await this.$http.post(
        '/mdm/mdmPriceSettingExtController/searchAllPrice', params,
      );
      // const res = await this.$http.post(
      //   '/mdm/mdmPriceSettingExtController/orderSearchAllPrice', params,
      // );
      if (!res.success || res.code !== 200) {
        this.$message.error(res.message);
        return;
      }
      this.$nextTick(() => {
        if (res.result) {
          // 询价后设置价格，价格为null，默认为0
          if (res.result.itemResultVoList && res.result.itemResultVoList.length > 0) {
            const price = res.result.itemResultVoList[0].price || 0;
            const productTotalPrice = _.multiply(Number(row.num || 0), Number(price || 0)).toFixed(2);
            this.$set(row, 'productPrice', price);
            this.$set(row, 'discountAfterPrice', price);
            this.$set(row, 'productTotalPrice', productTotalPrice);
            this.calculateReplenishCaptital();
            this.totalAmount();
          }
        }
      });
    },

    /**
     * 计算总金额
     * */
    totalAmount() {
      let { tableData } = this.drawerConfig[1];
      const { ruleForm } = this.drawerConfig[0];
      let amount = 0;
      let bcaptitalTotalAmount = 0;
      tableData = tableData.map((v) => {
        const obj = v;
        obj.bcaptitalTotalPrice = _.multiply(Number(obj.num || 0), Number(obj.discountAfterPrice || 0)).toFixed(2);
        return obj;
      });
      tableData.forEach((i) => {
        amount += Number(i.productTotalPrice);
        bcaptitalTotalAmount += Number(i.bcaptitalTotalPrice);
      });
      this.setFormData(amount, 'amount');
      const shouldPayAmount = amount;
      if (ruleForm.orderType !== '4') { // 非货补订单
        this.setFormData(shouldPayAmount, 'shouldPayAmount');
        if (ruleForm.businessType === 'B') { // 业务类型为B类
          // B类资金支付（元） 等于应付合计金额即订单总金额
          this.setFormData(amount, 'bcaptitalAmount');
          if (this.id || this.orderCode) {
            this.setFormData(bcaptitalTotalAmount, 'bcaptitalAmount');
          }
          // 资金账户支付 等于应付金额
          this.setFormData(amount, 'captitalAmount1');
          // 本单实际支付 等于应付金额
          this.setFormData(amount, 'actualAmount');
        } else { // 非货补订单 非B类订单
          this.setFormData(shouldPayAmount, 'captitalAmount1');
          this.setFormData(shouldPayAmount, 'actualAmount'); // 实际支付
        }
      } else {
        this.setFormData(amount - ruleForm.supplementAmount, 'captitalAmount');
        this.setFormData(amount - ruleForm.supplementAmount, 'shouldPayAmount');
        this.setFormData(0, 'actualAmount');
      }
    },

    /**
     * 设置列表头部按钮
     * @param index {number} 第几个按钮
     * @param state {boolean} 状态
     */
    setDrawerTableButton(index, state) {
      this.$set(this.drawerConfig[1].tableButtons[index], 'show', state);
    },

    // 选择订单类型  {1: 销售订单, 2: 处理品订单, 3: 促销品订单, 4:货补订单, 5:免费订单}
    selectOrderType(code, request) {
      // 处理商品类型
      if (code === '4') { // 订单类型为货补订单 默认商品类型为 {0: 货补资金}
        this.productType = '0';
        this.drawerConfig[1].tableButtons[1].show = true;
      } else { // 非货补订单 默认商品类型为 {3: 本品}
        this.productType = '3';
        this.drawerConfig[1].tableButtons[1].show = false;
      }

      // 设置关联活动
      if (code === '3') {
        this.setFormItemValue(['relateActName', 'relateActCode', 'actCash'], 'show', true);
      } else {
        this.setFormItemValue(['relateActName', 'relateActCode', 'actCash'], 'show', false);
      }

      // 列表显示  || 处理品 || 促销品订单  || 内部订单
      if (code === '5' || code === '2' || code === '3' || code === '7') {
        this.tableConfigProduct.rows = [
          {
            label: '商品编码',
            prop: 'materialCode',
          },
          {
            label: '商品名称',
            prop: 'materialName',
          },
        ];
      } else {
        this.tableConfigProduct.rows = [
          {
            label: '商品编码',
            prop: 'productCode',
          },
          {
            label: '商品名称',
            prop: 'productName',
          },
          {
            label: '规格',
            prop: 'spec',
          }, {
            label: '单位',
            prop: 'saleUnitName',
          },
        ]; // 设置商品列表配置
      }
      if (!request) {
        this.closeCapital();
      }
    },

    /**
     * 设置Drawer弹窗组件表单的数据
     */
    setDrawerTable(title, names, valueName, value) {
      this.drawerConfig.map((i) => {
        if (i.title === title) {
          i.tableConfig.rows.map((k) => {
            if (names.includes(k.prop)) {
              this.$set(k, valueName, value);
            }
            return k;
          });
        }
        return i;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.drawer-button {
  padding: 20px 20px;
  text-align: right;
  position: absolute;
  bottom: 0;
  background-color: #fff;
  width: 100%;
  border-top: 1px solid #ebeef5;
}

.drawer-table {
  padding: 20px;
  margin-bottom: 100px;
}
.label {
  display: inline-block;
  width: 120px;
  text-align: justify;
  text-align-last: justify;
}
.slot-body {
  display: flex;
  width: 80%;
  justify-content: space-between;
  > div {
    display: flex;
    align-items: center;
    span {
      flex-shrink: 0;
      margin-right: 5px;
    }
    input {
      width: 100px;
    }
  }
}
/deep/.el-input__inner{
  &::placeholder{
    font-weight: bold;
  }
}
/deep/.el-textarea__inner{
   &::placeholder{
    font-weight: bold;
  }
}
/deep/.button-input span[data-v-76db450f]{
  font-weight: bold;
  display: inline-block;
  width: 100%;
}
</style>

<style lang="less">
.needGoodsForm {
  .slot-body {
    > div {
      input {
        width: 100px;
      }
    }
  }
}
</style>
