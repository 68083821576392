<script>
import TablePage from '@/found/components/table_page';
import Vue from 'vue';
import FormPage from './purchase_order_form.vue';
import DealerScanList from './dealer_scan_list.vue';
import TerminalScanList from './terminal_scan_list.vue';
import CustomerScanList from './customer_scan_list.vue';

export default {
  extends: TablePage,
  components: {
    FormPage,
    DealerScanList,
    TerminalScanList,
    CustomerScanList,
  },
  data() {
    return {
      formConfig: {},
    };
  },
  props: {
    propsObj: Object,
  },
  mounted() {
    this.params = { custCode: this.propsObj.row.customerCode };
    this.getConfigList('need-goods-list');
  },
  methods: {
    modalClick({ val, row }) {
      const formPageArg = {
        drawerTitle: '',
        code: 'view', // 按钮对应code
        orderCode: '', // 数据code
        formShow: true,
      };
      if (val.code === 'order_details') {
        formPageArg.drawerTitle = '查看';
        formPageArg.orderCode = row.orderCode;
        this.createFormPage(formPageArg);
      }
      if (val.code === 'view') {
        formPageArg.drawerTitle = '查看';
        formPageArg.orderCode = row.orderCode;
        this.createFormPage(formPageArg);
      }
      if (val.code === 'dealer_scan_code_record') {
        this.formName = 'DealerScanList';
        this.propsObjInData.row = { ...this.propsObj.row, orderCode: row.orderCode };
        this.modalConfig.title = '经销商扫码记录';
        this.openFull();
      }
      if (val.code === 'terminal_scan_code_record') {
        this.formName = 'TerminalScanList';
        this.propsObjInData.row = { ...this.propsObj.row, orderCode: row.orderCode };
        this.modalConfig.title = '终端扫码记录';
        this.openFull();
      }
      if (val.code === 'customer_scan_code_record') {
        this.formName = 'CustomerScanList';
        this.propsObjInData.row = { ...this.propsObj.row, orderCode: row.orderCode };
        this.modalConfig.title = '消费者扫码记录';
        this.openFull();
      }
    },
    createFormPage(arg) {
      const that = this;
      const Component = Vue.extend(FormPage);
      const vm = new Component({
        el: document.createElement('div'),
        data() {
          return {
            ...arg,
          };
        },
        methods: {
          getParentTable() {
            that.getConfigList('need-goods-list');
          },
        },
      });
      document.body.appendChild(vm.$el);
    },
  },
};
</script>
